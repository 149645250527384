import { HYDRATE } from 'next-redux-wrapper'
import {
  SET_COIN_DATA,
  SET_COIN_DATA_ERROR,
  REFRESH_COIN_DATA,
  SET_COIN_RELATED_NEWS,
} from 'src/redux/actions'

const initialState = {
  data: null,
  error: null,
}

export const coin = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        data: state.data || action.payload.coin.data,
      }
    case SET_COIN_DATA:
      return {
        ...state,
        data: action.payload,
        error: null,
      }
    case SET_COIN_DATA_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case REFRESH_COIN_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case SET_COIN_RELATED_NEWS:
      return {
        ...state,
        data: {
          ...state.data,
          relatedNews: action.payload,
        },
        error: null,
      }
    default:
      return state
  }
}

export default coin
