import { useRef, forwardRef, ReactNode, HTMLAttributes, RefObject } from 'react'
import clsx from 'clsx'
import {
  OverlayAria,
  OverlayProps,
  useModal,
  useOverlay,
  usePreventScroll,
} from '@react-aria/overlays'
import { mergeProps, useViewportSize } from '@react-aria/utils'
import styles from './styles.module.scss'
import { DrawerOverlay } from './DrawerOverlay'
import { DrawerUnderlay } from './DrawerUnderlay'

export type DrawerProps = OverlayProps & {
  children: ReactNode
  isFixedHeight?: boolean
  isNonModal?: boolean
  container?: Element
}

export type DrawerWrapperProps = DrawerProps & {
  overlayProps?: HTMLAttributes<HTMLElement>
}

export const Drawer = forwardRef(
  (props: DrawerProps, ref: RefObject<HTMLDivElement>) => {
    const { children, onClose, isFixedHeight, isNonModal, ...otherProps } =
      props
    const domRef = useRef(ref?.current)
    const { overlayProps, underlayProps }: OverlayAria = useOverlay(
      { ...props, isDismissable: true },
      domRef
    )

    return (
      <DrawerOverlay {...otherProps}>
        <DrawerUnderlay {...underlayProps} />
        <DrawerWrapper
          isOpen={props.isOpen}
          onClose={onClose}
          ref={domRef}
          overlayProps={overlayProps}
          isFixedHeight={isFixedHeight}
          isNonModal={isNonModal}
          {...props}
        >
          {children}
        </DrawerWrapper>
      </DrawerOverlay>
    )
  }
)

const DrawerWrapper = forwardRef(
  (props: DrawerWrapperProps, ref: RefObject<HTMLDivElement>) => {
    const {
      children,
      isOpen,
      isFixedHeight,
      isNonModal,
      overlayProps,
      ...otherProps
    } = props
    usePreventScroll()
    const { modalProps } = useModal({
      isDisabled: isNonModal,
    })

    // We need to measure the window's height in JS rather than using percentages in CSS
    // so that contents (e.g. menu) can inherit the max-height properly. Using percentages
    // does not work properly because there is nothing to base the percentage on.
    // We cannot use vh units because mobile browsers adjust the window height dynamically
    // when the address bar/bottom toolbars show and hide on scroll and vh units are fixed.
    // Also, the visual viewport is smaller than the layout viewport when the virtual keyboard
    // is up, so use the VisualViewport API to ensure the tray is displayed above the keyboard.
    const viewport = useViewportSize()
    const wrapperStyle: any = {
      '--visual-viewport-height': viewport.height + 'px',
    }

    const wrapperClassName = styles.traywrapper

    const className = clsx(
      styles.tray,
      { [styles.isopen]: isOpen },
      { [styles.isfixedheight]: isFixedHeight }
      // otherProps.className
    )

    const domProps = mergeProps(otherProps, overlayProps) as object

    return (
      <div className={wrapperClassName} style={wrapperStyle}>
        <div {...domProps} {...modalProps} className={className} ref={ref}>
          {children}
        </div>
      </div>
    )
  }
)
