import React from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import HeaderView from './HeaderView'
import styles from './styles.module.scss'

export const ViewTypeSwitcher = ({ headerView }) => {
  const router = useRouter()
  const { pathname, query } = router
  const viewType =
    pathname.split('/').includes('coin') ||
    pathname.split('/').includes('table')
      ? 'table'
      : 'map'
  const isWatchlist = pathname.includes('watchlist')
  const isExchanges = pathname.includes('exchange')
  const isNft = pathname.includes('nft')

  const getUrl = type => {
    let url = '/'
    switch (true) {
      case isWatchlist:
        if (type === 'table') {
          url = '/watchlist/table'
        } else {
          url = '/watchlist'
        }
        break
      case isExchanges:
        if (type === 'table') {
          url = '/exchange/table'
        } else {
          url = '/exchange/'
        }
        break
      case isNft:
        if (type === 'table') {
          url = '/nft/table'
        } else {
          url = '/nft/'
        }
        break
      default:
        if (type === 'table') {
          url = '/coin'
          delete query.group
        }
    }

    const searchQuery = new URLSearchParams(query).toString()

    return `${url}${searchQuery && `?${searchQuery}`}`
  }

  if (headerView) {
    return <HeaderView getUrl={getUrl} viewType={viewType} />
  }

  return (
    <div className={clsx(styles.buttons, { [styles.buttonsNFT]: isNft })}>
      <Link
        className={clsx(
          styles.viewtype,
          styles.button,
          { [styles.viewTypeNft]: isNft },
          { [styles.active]: viewType === 'map' },
          { [styles.activeNft]: viewType === 'map' && isNft }
        )}
        href={getUrl('map')}
        prefetch={false}
        passHref
      >
        <Image
          className={styles.imagee}
          src={`/assets/icons/icon-map${
            viewType === 'map' ? '-black' : ''
          }.svg`}
          width='16'
          height='16'
          alt='Map view'
        />{' '}
      </Link>
      <Link
        className={clsx(
          styles.viewtype,
          styles.button,
          { [styles.viewTypeNft]: isNft },
          { [styles.active]: viewType === 'table' },
          { [styles.activeNft]: viewType === 'table' && isNft }
        )}
        href={getUrl('table')}
        prefetch={false}
        passHref
      >
        <Image
          src={`/assets/icons/icon-${
            viewType === 'table' ? 'table-black' : 'table'
          }.svg`}
          width='16'
          height='16'
          alt='Table view'
        />
      </Link>
    </div>
  )
}

export default ViewTypeSwitcher
