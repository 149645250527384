import { createSelector } from 'reselect'
import { getMapDataSelector } from './mapdata'

export const getCoinDataSelector = ({ coin }) => coin.data

export const getCompleteEntityData = createSelector(
  [getCoinDataSelector, getMapDataSelector],
  (data, mapData) => {
    if (!data) return null
    const dataArray = mapData?.coins?.data || []
    const currentEntityId = data?.slug
    if (!Array.isArray(dataArray) || !currentEntityId) return null

    return data
  }
)
