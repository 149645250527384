import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const useWidth = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isResized, setIsResized] = useState()
  const [getWidthWindow, setGetWidthWindow] = useState(null)
  const [getHeightWindow, setGetHeightWindow] = useState(null)
  const [isLandscape, setIsLandscape] = useState(false)

  const handleResize = useDebouncedCallback(() => setIsResized(Date.now()), 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updateDimensions = () => {
        setIsMobile(window.innerWidth <= 1024)
        setGetWidthWindow(window.innerWidth)
        setGetHeightWindow(window.innerHeight)
        setIsLandscape(
          window.matchMedia('(orientation: landscape)').matches &&
            window.innerWidth <= 1024
        )
      }

      updateDimensions()
      window.addEventListener('resize', handleResize)
      window.addEventListener('resize', updateDimensions)

      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('resize', updateDimensions)
      }
    }
  }, [handleResize])

  return { isMobile, getWidthWindow, getHeightWindow, isLandscape }
}

export default useWidth
