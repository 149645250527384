export function getNameFromCoinSlug(slug) {
  const n = slug.lastIndexOf('-')
  const name = n !== -1 ? slug.substring(0, n) : slug
  return name
    .split('-')
    .map(s => capitalize(s))
    .join(' ')
}

export function capitalize(s) {
  return !!s ? s[0].toUpperCase() + s.slice(1) : s
}
