import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Session, {
  useSessionContext,
} from 'supertokens-auth-react/recipe/session'
import { EmailVerificationClaim } from 'supertokens-auth-react/recipe/emailverification'
import { setAuthenticated, setUnauthenticated } from 'src/redux/actions'

function useAuth() {
  const session = useSessionContext()
  const dispatch = useDispatch()
  const previousSessionExists = useRef(false)

  const [user, setUser] = useState({})
  const [emailVerified, setEmailVerified] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)

  const validateClaims = async () => {
    const hasSession = await Session.doesSessionExist()
    if (hasSession) {
      const validationErrors = await Session.validateClaims()
      if (validationErrors.length === 0) {
        setEmailVerified(true)
      } else {
        const emailVerificationError = validationErrors.find(
          err => err.validatorId === EmailVerificationClaim.id
        )
        setEmailVerified(!emailVerificationError)
      }
    }
  }
  useEffect(() => {
    if (!session.loading) {
      const { doesSessionExist, userId, accessTokenPayload } = session

      if (!doesSessionExist) {
        if (previousSessionExists.current) {
          dispatch(setUnauthenticated())
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'logout',
            user_id: user?.userId ?? '',
          })
        }
        previousSessionExists.current = false
        setIsAuthorized(false)
        setUser({})
      } else {
        if (!previousSessionExists.current) {
          dispatch(setAuthenticated())
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'login',
            user_id: userId,
          })
          previousSessionExists.current = true
        }
        setIsAuthorized(true)
        const email = accessTokenPayload?.email
        setUser({ email, userId })
        validateClaims()
      }
    }
  }, [session, dispatch])

  useEffect(() => {
    if (emailVerified && user?.userId) {
      const localStorageKey = `signUpFired_${user.userId}`
      const alreadyFired = localStorage.getItem(localStorageKey)

      if (!alreadyFired) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'sign_up',
          user_id: user.userId,
        })
        localStorage.setItem(localStorageKey, 'true')
      }
    }
  }, [emailVerified, user])

  return {
    user,
    emailVerified,
    isAuthorized,
  }
}

export default useAuth
