import React, { useState } from 'react'
import styles from './styles.module.scss'
import { saveUserDetails } from 'src/services/userServices'

interface Props {
  onSaved: () => void // Callback to notify parent component when username is saved
}

export const CreateUsername: React.FC<Props> = ({ onSaved }) => {
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError('')
    e.preventDefault()
    try {
      await saveUserDetails(username) // You can also include other parameters if needed
      onSaved() // Inform parent component that the username has been saved
    } catch (e) {
      setError('There was an error saving the username. Please try again.')
    }
  }
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formHeader}>
        <h2 className={styles.title}>Create a username</h2>
        <p className={styles.text}>
          Please enter a unique username, that will be visible on your public
          profile and in private communication
        </p>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Create a username</label>
        <input
          className={styles.input}
          type='text'
          value={username}
          onChange={e => setUsername(e.target.value)}
          required={true}
        />
      </div>
      <button type={'submit'} className={styles.button}>
        Next
      </button>
    </form>
  )
}
