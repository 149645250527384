import subHours from 'date-fns/subHours'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import isArray from 'lodash/isArray'
import {
  setCoinsRangeAction,
  setExchangesRangeAction,
  setCurrentEntity,
  setExchangesMarketsRangeAction,
} from 'src/redux/actions'
import {
  setCoinsDisplay,
  setCoinsTop,
  setCoinsDependsOn,
  setSharedCurrency,
  addException,
  setGroupByEntity,
  addExchangesException,
  setExchangesTop,
  changeExchangesPeriod,
  setCoinsRanking,
  setCoinsTag,
} from 'src/redux/thunks'
import { changeCoinsPeriod } from 'src/redux/thunks'
import { ENTITY } from 'src/consts'
import { knownCurrencies } from 'src/consts'

const knownPeriods = { '1h': true, '24h': true, '7d': true, '30d': true }

const getPeriod = period => {
  const subAction =
    {
      '1h': subHours,
      '24h': subHours,
      '7d': subDays,
      '30d': subMonths,
    }[period] || subMonths
  const subPeriod =
    {
      '1h': 1,
      '24h': 24,
      '7d': 7,
      '30d': 1,
    }[period] || 1
  if (knownPeriods[period]) {
    return {
      active: period,
      start: Number(subAction(Date.parse(new Date()), subPeriod)),
      end: Number(Date.parse(new Date())),
    }
  }

  if (/^\[.*?\]$/g.test(period)) {
    try {
      const [start, end] = JSON.parse(period)
      return {
        active: 'custom',
        start,
        end,
      }
    } catch (e) {
      /* */
    }
  }

  return null
}

export const populateFilterStore =
  (query = {}, type) =>
  async dispatch => {
    const {
      currency,
      display,
      slice,
      dependsOn,
      period,
      range,
      exceptions,
      group,
      markets,
      ranking,
      tag,
    } = query

    if (type) {
      await dispatch(setCurrentEntity(type))
    }

    if (currency) {
      const preparedCurrency = knownCurrencies[currency] ? currency : 'USD'
      dispatch(setSharedCurrency(preparedCurrency))
    }

    if (display) {
      dispatch(setCoinsDisplay(display))
    }

    if (slice) {
      if (type === ENTITY.COIN) dispatch(setCoinsTop(slice))
      else if (type === ENTITY.EXCHANGE) dispatch(setExchangesTop(slice))
    }

    if (dependsOn) {
      dispatch(setCoinsDependsOn(dependsOn))
    }

    if (period) {
      const periods = getPeriod(period)
      if (periods) {
        if (type === ENTITY.COIN) dispatch(changeCoinsPeriod(periods))
        else if (type === ENTITY.EXCHANGE)
          dispatch(changeExchangesPeriod(periods))
      }
    }

    if (range) {
      try {
        const rangeArray = JSON.parse(range)
        if (type === ENTITY.COIN) dispatch(setCoinsRangeAction(rangeArray))
        else if (type === ENTITY.EXCHANGE)
          dispatch(setExchangesRangeAction(rangeArray))
      } catch (e) {
        console.error(e)
      }
    }

    if (exceptions) {
      let preparedExceptions = []
      try {
        const parsedExceptions = exceptions.replace(/\[|\]/gi, '').split(',')
        if (
          isArray(parsedExceptions) &&
          parsedExceptions.every(i => typeof i === 'string')
        ) {
          preparedExceptions = parsedExceptions
        }
      } catch (err) {
        console.error(err)
      }
      if (type === ENTITY.COIN) dispatch(addException(preparedExceptions))
      else if (type === ENTITY.EXCHANGE)
        dispatch(addExchangesException(preparedExceptions))
    }

    if (group) {
      dispatch(setGroupByEntity(group))
    }

    if (markets) {
      try {
        const marketsArray = JSON.parse(markets)
        dispatch(setExchangesMarketsRangeAction(marketsArray))
      } catch (e) {
        console.error(e)
      }
    }

    if (ranking) {
      dispatch(setCoinsRanking(ranking))
    }

    if (tag) {
      dispatch(setCoinsTag(tag))
    }
  }
