import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'

export const ViewTypeSwitcher = ({ getUrl, viewType }) => {
  const isMap = viewType === 'map'
  const icon = isMap ? 'table' : 'map'
  return (
    <Link
      className={styles.iconContainer}
      href={getUrl(isMap ? 'table' : 'map')}
      prefetch={false}
      passHref
    >
      <Image
        src={`/assets/icons/icon-${icon}.svg`}
        width='18'
        height='18'
        alt='Table icon'
      />
    </Link>
  )
}

export default ViewTypeSwitcher
