import React from 'react'
import useAuth from 'src/hooks/useAuth'
import styles from './styles.module.scss'

export const EmailVerificationMessage = () => {
  const { isAuthorized, emailVerified } = useAuth()

  return isAuthorized && !emailVerified ? (
    <div className={styles.container}>
      <div className={styles.message}>
        <h2>Please, check your email an verify your account</h2>
      </div>
    </div>
  ) : null
}
