import React from 'react'
import toast from 'react-hot-toast'
import Image from 'next/image'
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid'
import { combinedAnalytics } from 'src/utils/common'
import {
  getActiveWatchlistId,
  getAllWatchListItems,
  getMapDataArrayRaw,
} from 'src/redux/selectors'
import { getActiveWatchlist } from 'src/redux/selectors/watchlist'
import {
  addWatchListItemsAction,
  addWatchListItemsArrAction,
  changeActiveWatchListAction,
  clearWatchListItemsAction,
  deleteWatchListTabAction,
  renameWatchListTabAction,
  updateWatchListIconAction,
  initializeWatchListTabAction,
  setLatestActiveTab,
  deleteAllWatchListTabsAction,
} from 'src/redux/actions'
import {
  getWatchlistRemote,
  saveWatchlistRemote,
  deleteWatchlistRemote,
} from 'src/services/watchlistServices'

export const addRecommendedSets =
  ({ actionData, text }, data) =>
  async (dispatch, getState) => {
    const state = getState()
    const dataArray = data || getMapDataArrayRaw(state)
    const activeWatchList = getActiveWatchlist(state)
    let nextWatchLists = getAllWatchListItems(state) || []

    const resultArr = []
    const resultPnl = {}

    if (actionData.tag) {
      const itemsWithTag = dataArray.filter(
        item => item.tags && item.tags.includes(actionData.tag)
      )
      resultArr.push(...itemsWithTag.map(item => item.slug))
    }

    if (actionData.coins && typeof actionData.coins === 'number') {
      const itemsOfCoinCategory = dataArray.filter(
        item => item.category === 'coin'
      )
      resultArr.push(
        ...itemsOfCoinCategory.slice(0, actionData.coins).map(item => item.slug)
      )
    }

    if (actionData.tokens && typeof actionData.tokens === 'number') {
      const itemsOfTokensCategory = dataArray.filter(
        item => item.category === 'token'
      )
      resultArr.push(
        ...itemsOfTokensCategory
          .slice(0, actionData.tokens)
          .map(item => item.slug)
      )
    }

    if (actionData.tokens && typeof actionData.tokens === 'object') {
      const tokens = new Map(
        actionData.tokens.map(token => [token.symbol, token.balance])
      )
      dataArray.forEach(item => {
        if (tokens.has(item.symbol)) {
          resultArr.push(item.slug)
          resultPnl[item.slug] = { amount: tokens.get(item.symbol) }
        }
      })
    }

    nextWatchLists = nextWatchLists.map(item => {
      const newItem = { ...item }
      if (item.id === activeWatchList.id) {
        newItem.data = resultArr
        newItem.name = text
        newItem.pnl = resultPnl
      }
      return newItem
    })

    saveWatchlist(nextWatchLists, state.auth.isAuth)
    dispatch(addWatchListItemsArrAction(nextWatchLists))
  }

export const addWatchListItems = data => dispatch => {
  dispatch(addWatchListItemsAction(data))
}

export const addWatchListItemsArr = data => dispatch => {
  dispatch(addWatchListItemsArrAction(data))
}

export const changeActiveWatchList = data => dispatch => {
  dispatch(changeActiveWatchListAction(data))
}

export const deleteWatchListTab = () => async (dispatch, getState) => {
  const state = getState()
  const activeWatchlistId = getActiveWatchlistId(state)
  let allWatchLists = getAllWatchListItems(state) || []

  // Filter out the currently active watchlist
  allWatchLists = allWatchLists.filter(item => item.id !== activeWatchlistId)

  // If no watchlists remain, remove from remote source
  if (allWatchLists.length === 0) {
    deleteWatchlistRemote()
  } else {
    saveWatchlist(allWatchLists, state.auth.isAuth)
  }

  // Dispatch Redux action to remove the watchlist tab
  dispatch(deleteWatchListTabAction(activeWatchlistId))

  // Push the watchlist_removed event to dataLayer
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'watchlist_removed',
      watchlist_id: activeWatchlistId,
    })
  }
}

export const renameWatchListTab = (id, name) => async (dispatch, getState) => {
  const state = getState()
  const allWatchLists = getAllWatchListItems(state) || []

  // Update the name of the tab with the matching id
  const updatedWatchLists = allWatchLists.map(tab =>
    tab.id === id ? { ...tab, name } : tab
  )

  // Dispatch the action to update the state
  dispatch(renameWatchListTabAction({ id, name }))

  // Save the updated watchlist to the backend or local storage
  saveWatchlist(updatedWatchLists, state.auth.isAuth)
}

export const updateWatchListIcon =
  (id, iconId) => async (dispatch, getState) => {
    const state = getState()
    const allWatchLists = getAllWatchListItems(state) || []

    const tabsUpdate = allWatchLists.map(tab =>
      tab.id === id ? { ...tab, iconUrl: iconId } : tab
    )

    combinedAnalytics('Button', 'Button', 'Watchlist', 'Change watchlist icon')
    dispatch(updateWatchListIconAction(tabsUpdate))
    saveWatchlist(tabsUpdate, state.auth.isAuth)
  }

export const clearWatchListItems = () => async (dispatch, getState) => {
  const state = getState()
  const activeWatchlistId = getActiveWatchlistId(state)
  let allWatchLists = getAllWatchListItems(state) || []

  allWatchLists = allWatchLists.map(item => {
    const currentTab = { ...item }
    if (item.id === activeWatchlistId) {
      currentTab.data = []
    }
    return currentTab
  })
  dispatch(clearWatchListItemsAction(allWatchLists))
  saveWatchlist(allWatchLists, state.auth.isAuth)
}

export const deleteWatchListItem = symbol => async (dispatch, getState) => {
  const state = getState()
  const activeWatchlist = getActiveWatchlist(state)
  let allWatchLists = getAllWatchListItems(state) || []

  const deleteItem = activeWatchlist.data.filter(item => item !== symbol)

  allWatchLists = allWatchLists.map(item => {
    const currentTab = { ...item }
    if (item.id === activeWatchlist.id) {
      currentTab.data = deleteItem
    }
    return currentTab
  })

  toast(
    <>
      {symbol} removed from&nbsp;<Link href='/watchlist'>watchlist</Link>
    </>,
    {
      icon: (
        <Image
          src='/assets/toast/remove.svg'
          width={24}
          height={24}
          alt='Remove icon'
        />
      ),
    }
  )

  // Existing custom analytics call
  combinedAnalytics('Watchlist', 'Watchlist', 'Interaction:removed', symbol)

  // Push to dataLayer: token_removed event
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'token_removed',
      token_name: symbol,
      watchlist_id: activeWatchlist.id,
    })
  }

  dispatch(addWatchListItemsArrAction(allWatchLists))
  saveWatchlist(allWatchLists, state.auth.isAuth)
}

export const addWatchListItem = symbol => async (dispatch, getState) => {
  const state = getState()
  const activeWatchlist = getActiveWatchlist(state)
  let allWatchLists = getAllWatchListItems(state) || []

  // Update the active watchlist with the new token
  allWatchLists = allWatchLists.map(item => {
    const currentTab = { ...item }
    if (item.id === activeWatchlist.id) {
      currentTab.data = [...currentTab.data, symbol]
    }
    return currentTab
  })

  // Update the Redux store
  dispatch(addWatchListItemsArrAction(allWatchLists))

  // Save watchlist to backend
  saveWatchlist(allWatchLists, state.auth.isAuth)

  // Push the event to dataLayer
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'token_added',
      token_name: symbol, // parameter: token_name
      watchlist_id: activeWatchlist.id, // parameter: watchlist_id
    })
  }
}
export const setWatchListItemPnL =
  (slug, amount, tradePrice) => async (dispatch, getState) => {
    const state = getState()
    const allWatchLists = getAllWatchListItems(state) || []
    const activeWatchlist = getActiveWatchlist(state)

    const nextWatchListItems = allWatchLists.map(item => {
      const newItemsList = { ...item }
      if (item.id === activeWatchlist.id) {
        newItemsList.pnl = {
          ...(newItemsList.pnl || {}),
          ...{
            [slug]: { amount, tradePrice },
          },
        }
      }
      return newItemsList
    })

    dispatch(addWatchListItemsArr(nextWatchListItems))
    saveWatchlist(nextWatchListItems, state.auth.isAuth)
  }

const watchlistSymbolsId = uuidv4()

export const getWatchlistTabs =
  ({ watchlistSymbols, isAuth } = {}) =>
  async (dispatch, getState) => {
    const watchListFromState = getAllWatchListItems(getState())
    if (isAuth === undefined) {
      return
    }

    if (!isAuth && watchListFromState.length > 0) {
      return
    }

    if (isAuth && !!watchListFromState.remoteFetched) {
      return
    }

    if (watchlistSymbols) {
      const tab = {
        name: 'Watchlist Symbols',
        id: watchlistSymbolsId,
        data: watchlistSymbols,
      }
      dispatch(addWatchListItemsArr([tab]))
      dispatch(changeActiveWatchList(tab.id))
      return
    }

    let watchList
    if (isAuth) {
      watchList = await getWatchlistRemote(isAuth)
      watchList.remoteFetched = true
    }

    if (watchList && watchList.length > 0) {
      watchList = watchList.filter(item => item.id !== 'main')
      dispatch(addWatchListItemsArr(watchList))
    }
  }

function saveWatchlist(watchLists, isAuth) {
  if (isAuth) {
    saveWatchlistRemote(watchLists, isAuth)
  } else {
    console.log('User is not authenticated, cannot save watchlist.')
  }
}

export const addNewWatchListTab = () => async (dispatch, getState) => {
  const state = getState()
  const allWatchLists = getAllWatchListItems(state)

  if (allWatchLists.length < 10) {
    const newTabId = uuidv4()
    const newTab = {
      id: newTabId,
      name: `My Watchlist ${allWatchLists.length + 1}`,
      data: [],
    }

    // Dispatch local updates
    dispatch(initializeWatchListTabAction(newTab))
    dispatch(setLatestActiveTab(newTabId))

    try {
      // Save watchlist to backend
      await saveWatchlistRemote([...allWatchLists, newTab], state.auth.isAuth)

      // Push the event to dataLayer (only if window is defined)
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'watchlist_created',
          watchlist_id: newTabId,
        })
      }
    } catch (error) {
      console.error('Error saving watchlist:', error)
      // Handle error if needed
    }

    return newTabId
  }

  return null
}

export const deleteAllWatchListTabs = () => async (dispatch, getState) => {
  try {
    // Call the remote API to delete all watchlists
    await deleteWatchlistRemote()

    // Dispatch the action to delete all watchlists locally
    dispatch(deleteAllWatchListTabsAction())

    // Clear loading state
  } catch (error) {
    console.error('Error deleting all watchlists:', error)
  }
}
