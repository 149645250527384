import React from 'react'
import { useProgressBar } from '@react-aria/progress'
import { AriaProgressBarProps } from '@react-types/progress'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type ProgressBarProps = AriaProgressBarProps & {
  className?: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { className, label, value, minValue = 0, maxValue = 100 } = props
  const { progressBarProps, labelProps } = useProgressBar(props)

  const percentage = (value - minValue) / (maxValue - minValue)
  const barWidth = `${Math.round(percentage * 100)}%`

  return (
    <div {...progressBarProps} className={clsx(styles.progressBar, className)}>
      <div className={styles.label}>
        {label && <span {...labelProps}>{label}</span>}
        {!!progressBarProps['aria-valuetext'] && (
          <span>{progressBarProps['aria-valuetext']}</span>
        )}
      </div>
      <div className={styles.progressBarBackground}>
        <div
          className={styles.progressBarPercent}
          style={{ width: barWidth }}
        />
      </div>
    </div>
  )
}
