import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'

export type LoadingProps = {
  className?: string
}

export const Loading = ({ className = '' }) => {
  return (
    <div className={clsx(styles.loading, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
