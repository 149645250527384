import React, { useState } from 'react'
import styles from './styles.module.scss'

export const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState('')

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Forgot your password?</h2>
      <p className={styles.text}>
        Enter the email associated with you account and we will send an email
        with instructions on how to rest your password
      </p>
      <div className={styles.formGroup}>
        <label className={styles.label}>Email</label>
        <input
          className={styles.input}
          type='email'
          defaultValue='Email address...'
          value={emailAddress}
          onChange={e => setEmailAddress(e.target.value)}
        />
      </div>
      <button className={styles.button}>Send reset link</button>
    </div>
  )
}
