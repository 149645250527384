import { useEffect, useRef } from 'react'

const useDidUpdate = (f, conditions) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    // On first render, just set didMountRef and return immediately
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }

    // Call f if it's a function, but do NOT return its result
    if (typeof f === 'function') {
      f()
    }
  }, conditions)
}

export default useDidUpdate
