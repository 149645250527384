import React from 'react'
import styles from './styles.module.scss'

export const CheckInboxComponent = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Check your inbox</h2>
      <p className={styles.text}>
        Instructions how to reset your password has been sent to your email
      </p>
      <button className={styles.button}>Got it</button>
    </div>
  )
}
