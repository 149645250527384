import { ReactNode, useRef } from 'react'
import { AriaDialogProps } from '@react-types/dialog'
import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
  OverlayProps,
} from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { useButton } from '@react-aria/button'
import { useIsSSR } from '@react-aria/ssr'
import Image from 'next/image'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import styles from './styles.module.scss'

export type DialogProps = AriaDialogProps &
  Omit<
    OverlayProps,
    'isOpen' | 'isDismissable' | 'isKeyboardDismissDisabled'
  > & {
    children: ReactNode
    dismissable?: OverlayProps['isDismissable']
    headerless?: boolean
    keyboardDismissDisabled?: OverlayProps['isKeyboardDismissDisabled']
    open?: OverlayProps['isOpen']
    padding?: boolean
    size?: 'small' | 'medium' | 'large' | 'xlarge'
    title: string
    dialogClassName?: string
  }

export const Dialog = (props: DialogProps) => {
  const ariaProps = transformProps(props)
  // Chrome touch is triggering other click on the underlay layer
  // https://github.com/adobe/react-spectrum/issues/1279
  ariaProps.onClose = debounce(ariaProps.onClose, 200)
  const {
    children,
    headerless = false,
    padding = true,
    isOpen,
    onClose,
    title,
    size = 'medium',
    dialogClassName,
  } = ariaProps

  const isSSR = useIsSSR()

  const closeButtonRef = useRef()
  const { buttonProps: closeButtonProps } = useButton(
    { onPress: onClose },
    closeButtonRef
  )

  usePreventScroll({ isDisabled: !isOpen })
  const ref = useRef()
  const { overlayProps, underlayProps } = useOverlay(ariaProps, ref)
  const { modalProps } = useModal()
  const { dialogProps, titleProps } = useDialog(ariaProps, ref)

  if (!isOpen || isSSR) return null

  const dialogCN = clsx(styles.dialog, dialogClassName, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.xlarge]: size === 'xlarge',
    [styles.padding]: padding,
  })

  return (
    <OverlayContainer>
      <div className={styles.underlay} {...underlayProps}>
        <FocusScope autoFocus contain restoreFocus>
          <div
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
            ref={ref}
            className={dialogCN}
          >
            {!headerless && (
              <header className={styles.header}>
                <h3 {...titleProps} className={styles.title}>
                  {title}
                </h3>
                <button
                  {...closeButtonProps}
                  className={styles.close}
                  ref={closeButtonRef}
                >
                  <Image
                    alt='Close'
                    width='16'
                    height='16'
                    src='/assets/icons/icon-close-cross.svg'
                  />
                </button>
              </header>
            )}
            {children}
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  )
}

export default Dialog

const transformProps = (props: DialogProps) => {
  const { open, dismissable, keyboardDismissDisabled, ...rest } = props
  return {
    ...rest,
    isOpen: open,
    isDismissable: dismissable,
    isKeyboardDismissDisabled: keyboardDismissDisabled,
  }
}
