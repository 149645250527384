import React, { useState, useEffect } from 'react'
import { Checkbox } from 'src/components/Checkbox'
import { changeSubscribe, checkSubscribe } from 'src/services/userServices'
import Dialog from 'src/components/Dialog'
import Styles from './styles.module.scss'

export const SubscribeComponent = () => {
  const [subscribe, setSubscribe] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hasChanged, setHasChanged] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      setLoading(true)
      const subscribed = await checkSubscribe()
      setSubscribe(subscribed)
      setLoading(false)
    }

    fetchSubscriptionStatus()
  }, [])

  const handleCheckboxChange = () => {
    setSubscribe(!subscribe)
    setHasChanged(true)
  }

  const handleSubmit = async () => {
    if (hasChanged) {
      try {
        const result = await changeSubscribe(subscribe)

        if (result) {
          setHasChanged(false)
          setDialogTitle(
            subscribe
              ? 'You are now subscribed to our weekly newsletter.'
              : 'You have unsubscribed from our weekly newsletter.'
          )
          setIsDialogOpen(true)
        } else {
          setDialogTitle(
            'Failed to update your subscription status. Please try again.'
          )
          setIsDialogOpen(true)
        }
      } catch (error) {
        console.error('Error handling subscription change:', error)
      }
    }
  }

  if (loading) {
    return <div>Loading...</div> // or any loader component you have
  }

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>Subscription to Our Weekly Newsletter</h2>
      <div className={Styles.right}>
        <label className={Styles.label}>
          <Checkbox
            className={Styles.checkbox}
            checked={subscribe}
            label=''
            onChange={handleCheckboxChange}
          />
        </label>
        <button
          className={Styles.button}
          onClick={handleSubmit}
          disabled={!hasChanged}
        >
          Save Changes
        </button>
      </div>
      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          title={dialogTitle}
          size='small'
        >
          <></>
        </Dialog>
      )}
    </div>
  )
}
