import React, { useState } from 'react'
import styles from './styles.module.scss'

export const NewPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError('')
    e.preventDefault()
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.title}>Create new password</h2>
      <p className={styles.text}>
        Your password must be at least 8 characthers long and contain at least
        one number or symbol
      </p>
      <div className={styles.formGroup}>
        <label className={styles.label}>Enter new password</label>
        <input
          className={styles.input}
          type='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          required={true}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>Confirm new password</label>
        <input
          className={styles.input}
          type='password'
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
          required={true}
        />
      </div>
      <button type={'submit'} className={styles.button}>
        Save Password
      </button>
    </form>
  )
}
