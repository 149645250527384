import React from 'react'
import styles from './styles.module.scss'
import Image from 'next/image'

export const SuccessComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src='/assets/icons/icon-check-black.svg'
          alt='Success'
          width={36}
          height={26}
        />
      </div>
      <h2 className={styles.title}>All Done!</h2>
      <p className={styles.text}>
        Welcome to Coin360! You are now logged in to your new account.
      </p>
      <a className={styles.continueButton}>Continue</a>
    </div>
  )
}
