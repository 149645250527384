import { useCallback, useLayoutEffect, useRef } from 'react'

export default function useEventCallback(fn) {
  const ref = useRef(fn)

  useLayoutEffect(() => {
    ref.current = fn
  })

  return useCallback((...args) => ref.current(...args), [])
}
