import axios from 'axios'
import sortBy from 'lodash/sortBy'
import {
  API_GET_EXCHANGES_MAPDATA_MARKETS,
  API_GET_MAPDATA,
  API_GET_MAPDATA_MARKETS,
  API_NEWS_ARTICLES,
  CONFIG,
  COIN_DETAIL_TABS,
  ENTITY,
} from 'src/consts'
import { getRemoteMapData } from 'src/redux/thunks'
import { getNameFromCoinSlug } from 'src/utils/coin'
import {
  refreshCoinData,
  setCoinData,
  setCoinDataError,
  setCoinRelatedNews,
} from '../actions'
import {
  getMarketsDataAction,
  getMarketsDataFailureAction,
  getMarketsDataSuccessAction,
  setMarketsCurrencyAction,
} from '../actions/markets'
import { getCompleteEntityData } from '../selectors'

export const getCoinData =
  ({ url = '', slug, isRefresh = false, currency }) =>
  async dispatch => {
    const currenyQuery = currency ? `?currency=${currency}` : ''
    try {
      const response = await axios.get(
        `${url}${API_GET_MAPDATA}/${slug}/card${currenyQuery}`
      )
      if (isRefresh) {
        dispatch(refreshCoinData(response.data))
      } else {
        dispatch(setCoinData(response.data))
      }
    } catch (error) {
      dispatch(setCoinDataError(error.message))
    }
  }

export const getCoinRelatedNews =
  ({ url = '', code }) =>
  async dispatch => {
    try {
      const response = await axios.get(`${url}${API_NEWS_ARTICLES}`, {
        params: {
          tag: code,
          source: CONFIG.NEWS_SRC_COIN_CARD_STRING,
          limit: 10,
        },
      })
      const news = sortBy(
        Object.entries(response.data.result).reduce(
          (acc, [key, value]) => [
            ...acc,
            ...value
              .map(entry => ({ ...entry, src: key }))
              .filter(e => Boolean(e.link)),
          ],
          []
        ),
        el => -new Date(el.date)
      ).slice(0, 24)
      dispatch(setCoinRelatedNews(news))
    } catch (error) {
      dispatch(setCoinDataError(error.message))
    }
  }

export const getCoinDetailTabData =
  ({ tab, url }) =>
  async (dispatch, getState) => {
    const coinData = getCompleteEntityData(getState())

    if (tab === COIN_DETAIL_TABS.markets) {
      dispatch(getRemoteMapData({ entity: ENTITY.EXCHANGE, url }))
    } else if (tab === COIN_DETAIL_TABS.news) {
      await dispatch(getCoinRelatedNews({ url, code: coinData?.code }))
    }
  }

export const getMarketsData =
  ({ slug, entity, currency = 'usd' }) =>
  async dispatch => {
    dispatch(getMarketsDataAction())
    dispatch(setMarketsCurrencyAction(currency))

    const DATA_MAP = {
      [ENTITY.COIN]: (market, index) => ({
        ...market,
        price: market.price < 0 ? 0 : market.price, // TODO: fix after api changes
        market: `${market.base}/${market.quote}`,
        index: index + 1,
      }),
      [ENTITY.EXCHANGE]: (market, index) => ({
        ...market,
        exchangePair: `${market.base}/${market.quote}`,
        coin: market.coin || {
          slug: market.base,
          symbol: market.base,
          name: getNameFromCoinSlug(market.base),
        },
        index: index + 1,
      }),
    }

    const path = {
      [ENTITY.COIN]: API_GET_MAPDATA_MARKETS,
      [ENTITY.EXCHANGE]: API_GET_EXCHANGES_MAPDATA_MARKETS,
    }[entity]

    try {
      let url = path.replace(':slug', slug)
      if (currency !== 'usd') {
        url += '?currency=' + currency
      }

      const response = await fetch(url)
      let json = await response.json()

      if (!!json.data) {
        json = json.data
      }

      dispatch(
        getMarketsDataSuccessAction({
          [entity]: json?.map(DATA_MAP[entity]),
        })
      )
    } catch (error) {
      console.error(error) // eslint-disable-line
      dispatch(getMarketsDataFailureAction())
    }
  }
